import Layout from "../components/global/Layout";
import React from "react";
import Header from "../components/page-specific/Seamless-To-Grubhub/Header";
import Benefits from "../components/page-specific/Seamless-To-Grubhub/Benefits";
import Testimonial from "../components/page-specific/Seamless-To-Grubhub/Testimonial";
import Faq from "../components/page-specific/Seamless-To-Grubhub/FAQ";
import styled from 'styled-components';
import { queries } from "../breakpoints"

const SeamlessToGrubhubPage = ({ location }) => {
  const headerInfo = {
    title: "Seamless Corporate is now Grubhub Corporate",
    subTitle: "Proudly serving corporate clients in New York City since 1999.",
  };

  const benefits1 = [
    {
      title: "Make work more delicious",
      description: "Boost employee morale and increase engagement by ordering tasty meals to your next in-office meeting or virtual happy hour.",
      img: "https://corporate-wp-stage.grubhub.com/wp-content/uploads/2022/01/icon-laptop.png",
    },
    {
      title: "Get more. Pay less.",
      description: "Clients have reported saving up to 30%* anually by setting a budget that fits their business order needs.",
      img: "https://corporate-wp-stage.grubhub.com/wp-content/uploads/2022/01/icon-wallet.png",
    },
  ];

  const benefits2 = [
    {
      title: "Cater to every taste",
      description: "With over 300,000 restaurants nationwide, your colleagues, employees, and clients can order the food they love and get it delivered anywhere.",
      img: "https://corporate-wp-stage.grubhub.com/wp-content/uploads/2022/01/icon-foods.png",
    },
    {
      title: "Support local restaurants",
      description: "Every order supports local New York City restaurants to continue serving up the food your team loves.",
      img: "https://corporate-wp-stage.grubhub.com/wp-content/uploads/2022/01/icon-map.png",
    },
  ]

  const benefits3 = []

  const testimonials = [
    {
      text: "Grubhub has been our go-to corporate meal solution since we started 13 years ago. The offerings have improved Y-O-Y, as well as the ease and convenience. The customer service is also #1!",
      img: "https://corporate-wp-stage.grubhub.com/wp-content/uploads/2022/01/TAG-logo.png",
      contactName: "Nichole Saland",
      contactTitle: "Managing Director, Telsey Advisory Group",
    },
  ];

  const faq = [
    {
      question: "What's the difference between a Seamless and Grubhub Corporate account?",
      answer: "A Seamless Corporate Account is now a Grubhub Corporate Account. It offers the same scalable, cost-effective way to feed employees, clients, guests and more. Sign up today to unlock delicious meals from your favorite New York City restaurants.",
    },
    {
      question: "How are Seamless and Grubhub related?",
      answer: "Seamless is a brand under the Grubhub umbrella, serving customers and businesses local takeout restaurants across all of New York City. From Manhattan to Brooklyn, Seamless and Grubhub have your corporate ordering covered.",
    }
  ];

  return (
    <Layout 
      location={location} 
      title={"Seamless to Grubhub | Seamless turns into Grubhub"}
      description={"A Grubhub Corporate Account helps thousands of companies feed their teams by simplifying the process of ordering meals. Make your hungry team happy today!"}
      utag_data={{
        pageGroup: "grubhub - lead generation",
        subGroup: "seamless-to-grubhub",
        pageName: "seamless-to-grubhub",
        brand: "grubhub"
      }}
    >
      <Header headerInfo = { headerInfo } />
      <TextContainer>
        <StandardText>Grubhub Corporate is a simple, customizable and cost-effective way to feed employees, clients and teams - no matter where they're working.</StandardText>
        <StandardText>Previously known as Seamless Corporate, Grubhub Corporate has the same great network of restaurants, convenient ordering options and fast delivery that New York Teams have loved for over 20 years.</StandardText>
      </TextContainer>
      <Benefits title="Discover the benefits of a Grubhub Corporate Account" benefits1={benefits1} benefits2={benefits2} benefits3={benefits3} />
      <Testimonial testimonials = {testimonials} />
      <Faq faq={faq} />
    </Layout>
  );
};

export default SeamlessToGrubhubPage

const TextContainer = styled.div`
  margin: 38px 23%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  @media (${queries.max_break_md}){
    margin: 40px 8% 0px 8%;
  }
  @media (${queries.max_break_sm}){
    margin: 40px 8% 0px 8%;
  }
`;

const StandardText = styled.p`
  text-align: center;
  font-family: "Scandia Regular", sans-serif;
  font-size: 18px;
  line-height: 26px;
  color: var(--color-secondary);
  @media (${queries.max_break_md}){

  }
  @media (${queries.max_break_sm}){

  }
`;