import { queries }  from "../../../../breakpoints"
import styled from 'styled-components';

export const MainContainer = styled.div`
    background-color: #F8F8F8;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    @media (${queries.max_break_md}){
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
    }
    @media (${queries.max_break_sm}){
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
    }
`;

export const TextSection = styled.div`
    max-width: 50%;
    padding-left: 12.5%;
    display: flex;
    align-items: flex-start;
    justify-content: space-evenly;
    text-align: left;
    flex-direction: column;
    @media (${queries.max_break_md}){
        padding: 55px 0px 30px 0px;
        width: 100%;
        max-width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
    }
    @media (${queries.max_break_sm}){
        width: 100%;
        max-width: 100%;
        height: auto;
        padding: 55px 0px 30px 0px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
    }
`;

export const Title = styled.h1`
    text-align: left;
    font-size: 46px;
    line-height: 56px;
    font-family: "Scandia Bold", sans-serif;
    font-weight: normal;
    margin-bottom: 15px;
    color: var(--color-secondary);
    @media (${queries.max_break_md}){
        min-height: 122px;
        font-family: "Scandia Bold", sans-serif;
        font-weight: normal;
        font-size: 30px;
        line-height: 35px;
        color: var(--color-secondary);
        text-align: center;
        margin: 0px 10.37%;
        text-align: center;
    }
    @media (${queries.max_break_sm}){
        min-height: 122px;
        font-family: "Scandia Bold", sans-serif;
        font-weight: normal;
        font-size: 30px;
        line-height: 35px;
        color: var(--color-secondary);
        text-align: center;
        margin: 0px 10.37%;
        text-align: center;
    }
`;

export const SubTitle = styled.h6`
    text-align: left;
    font-size: 18px;
    font-family: "Scandia Regular", sans-serif;
    font-weight: normal;
    margin-bottom: 20px;
    color: var(--color-secondary);
    line-height: 26px;
    padding-right: 37.5%;
    @media (${queries.max_break_md}){
        font-family: "Scandia Regular", sans-serif;
        font-weight: normal;
        font-size: 18px;
        line-height: 28px;
        color: var(--color-secondary);
        text-align: center;
        margin: 0px 12.5% 30px 12.5%;
        padding: 0px;
    }
    @media (${queries.max_break_sm}){
        font-family: "Scandia Regular", sans-serif;
        font-weight: normal;
        font-size: 18px;
        line-height: 28px;
        color: var(--color-secondary);
        text-align: center;
        margin: 0px 12.5% 30px 12.5%;
        padding: 0px;
    }
`;

export const CTAText = styled.p`
    width: 160px;
    margin: 0px auto;
    font-family: "Scandia Bold", sans-serif;
    font-size: 18px;
    line-height: 26px;
    font-weight: normal;
    color: #FFFFFF;
`;

export const ImageSection = styled.div`
    min-width: 50%;
    height: 37.5vw;
    background-color: #F8F8F8;
    background-image: url("https://corporate-wp-stage.grubhub.com/wp-content/uploads/2022/01/corp-hero-img-dsk.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center right;
    overflow: visible;
    @media (${queries.max_break_md}){
        height: 70.5vw;
        width: 100%;
        margin: 0px;
        background-image: url("https://corporate-wp-stage.grubhub.com/wp-content/uploads/2022/01/corp-hero-img-mob.png");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: bottom center;
    }
    @media (${queries.max_break_sm}){
        width: 100%;
        height: 50vh;
        margin: 0px;
        overflow: hidden;
        background-image: url("https://corporate-wp-stage.grubhub.com/wp-content/uploads/2022/01/corp-hero-img-mob.png");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: bottom center;
    }
`;