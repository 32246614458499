import { queries }  from "../../../../breakpoints"
import styled from 'styled-components';

export const MainContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    background-color: #F9F9F9;
    padding: 93px 0px;
    @media (${queries.max_break_md}){
        padding: 60px 0px;
    }
    @media (${queries.max_break_sm}){
        padding: 60px 0px;
    }
`;

export const Title = styled.h2`
    margin: 0px 12.5% 60px 12.5%;
    font-family: "Scandia Bold", sans-serif;
    font-weight: normal;
    font-size: 46px;
    color: var(--color-text-light);
    line-height: 32.81px;
    @media (${queries.max_break_md}){
        font-family: "Scandia Bold", sans-serif;
        margin: 0px 10% 33px 10%;
        font-size: 30px;
        color: var(--color-text-light);
        line-height: 35px;
    }
    @media (${queries.max_break_sm}){
        font-family: "Scandia Bold", sans-serif;
        margin: 0px 10% 33px 10%;
        font-size: 30px;
        color: var(--color-text-light);
        line-height: 35px;
    }
`;

export const FAQContainer = styled.div`
    margin: 36px 12.5%;
    width: 75%;
    @media (${queries.max_break_md}){
        margin: 0px 10% 37px 10%;
    }
    @media (${queries.max_break_sm}){
        margin: 0px 48px 37px 48px;
    }
`;

export const QuestionContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
    width: 100%;
`;

export const QuestionText = styled.p`
    margin: 0px;
    font-family: "Scandia Bold", sans-serif;
    font-size: 22px;
    color: var(--color-secondary);
    letter-spacing: 0;
    line-height: 26.48px;
    font-weight: normal;
`;

export const QuestionIcon = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 10px 0px;
`;

export const AnswerContainer = styled.div``;

export const AnswerText = styled.p`
    opacity: 0.87;
    font-family: "Scandia Regular", sans-serif;
    font-size: 18px;
    color: var(--color-text-light);
    letter-spacing: 0;
    line-height: 26px;
    margin-top: 20px;
    margin-bottom: 0px;
    @media (${queries.max_break_md}){
        opacity: 1;
    }
    @media (${queries.max_break_sm}){
        opacity: 1;
    }
`;

export const CTAContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: auto;
    margin-top: 9px;
    align-self: center;
    @media (${queries.max_break_md}){
        margin-top: 6px;
    }
    @media (${queries.max_break_sm}){
        margin-top: 6px;
    }
`;

export const CTAText = styled.p`
    width: 180px;
    margin: 0px auto;
    font-family: "Scandia Bold", sans-serif;
    font-size: 18px;
    line-height: 26px;
    font-weight: normal;
    color: #FFFFFF;
    @media (${queries.max_break_md}){
        width: 220px;
    }
    @media (${queries.max_break_sm}){
        width: 220px;
    }
`;
