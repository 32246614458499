import { MainContainer, TextSection, Title, SubTitle, CTAText, ImageSection } from "./Header.styles"
import Button from "../../../global/Button"
import { AnalyticsContext } from "../../../../context"
import React, { useContext } from 'react';

const Header = ({ headerInfo }) => {
  const { handleTealiumEvent } = useContext(AnalyticsContext);

  return(
    <MainContainer>
      <TextSection>
        <Title>{headerInfo.title}</Title>
        <SubTitle>{headerInfo.subTitle}</SubTitle>
        <Button
          to = "/contact-us/"
          type = "primary"
          onClick={() => handleTealiumEvent({ eventcategory: "seamless to grubhub", eventaction: "resources", eventlabel: "contact us" })}
        >
          <CTAText>
            Get started
          </CTAText>
        </Button>
      </TextSection>
      <ImageSection>
      </ImageSection>
    </MainContainer>
  );
};

export default Header;
