import React from 'react';

export const ArrowDownSVG = ({rotation}) => {
    return(
        <svg width="15px" height="9px" viewBox="0 0 15 9" version="1.1" xmlns="http://www.w3.org/2000/svg" transform={`rotate(${rotation})`}>
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Corporate_Seamless_Page_DSK" transform="translate(-1027.000000, -2355.000000)">
                    <g id="MODULE-FAQs-DSK" transform="translate(0.000000, 2157.000000)">
                        <g id="FAQs">
                            <g id="caret-right" transform="translate(1034.646532, 202.127568) rotate(-90.000000) translate(-1034.646532, -202.127568) translate(1022.146532, 187.627568)">
                                <rect id="bounds" x="0" y="0" width="24.8572194" height="28.125"></rect>
                                <polygon id="Shape" fill="var(--color-primary)" fillRule="nonzero" points="7.82648292 8.87461513 13.3360492 15.101108 13.3363195 13.0279754 7.82675325 19.2507947 9.65603614 21.3242328 15.1656024 15.1014134 16.0832229 14.0650003 15.1658727 13.0282808 9.65630647 6.80178793"></polygon>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}
