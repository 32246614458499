import { MainContainer, TestimonialContainer, ImageContainer, TestimonialText, ContactName, ContactTitle } from "./Testimonial.styles"
import React from 'react';

const Testimonial = ({testimonials}) => {
  return(
    <MainContainer>
      {
        testimonials.map((testimonial, index) => {
          return (
            <TestimonialContainer
              key={index}
            >
              <ImageContainer src={testimonial.img} />
              <TestimonialText>“{ testimonial.text }”</TestimonialText>
              <ContactName>{ testimonial.contactName }</ContactName>
              <ContactTitle>{ testimonial.contactTitle }</ContactTitle>
            </TestimonialContainer>
          );
        })
      }
    </MainContainer>
  );
};

export default Testimonial;
