import { queries }  from "../../../../breakpoints"
import styled from 'styled-components';

export const MainContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0% 0% 5% 0%;
    font-family: "Scandia Regular", sans-serif;
    @media (${queries.max_break_md}){
        margin: 43px 0px 75px 0px;
    }
    @media (${queries.max_break_sm}){
        margin: 43px 0px 75px 0px;
    }
`;

export const TestimonialContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 62.17%;
    @media (${queries.max_break_md}){
        width: 100%;
        margin: 0px 10%;
    }
    @media (${queries.max_break_sm}){
        width: 100%;
        margin: 0px 10%;
    }
`;

export const ImageContainer = styled.div`
    width: 50vw;
    max-width: 171px;
    height: 46px;
    background-image: url(${(props) => props.src});
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    @media (${queries.max_break_md}){
        width: 50vw;
        max-width: 100px;
        height: 50px;
    }
    @media (${queries.max_break_sm}){
        width: 50vw;
        max-width: 100px;
        height: 50px;
    }
`;

export const TestimonialText = styled.h2`
    text-align: center;
    margin: 21px 0px;
    font-family: "Scandia Bold", sans-serif;
    font-size: 28px;
    color: var(--color-secondary);
    letter-spacing: 0.13px;
    text-align: center;
    line-height: 38px;
    font-weight: normal;
    @media (${queries.max_break_md}){
        font-family: "Scandia Bold", sans-serif;
        font-weight: normal;
        font-size: 22px;
        color: var(--color-secondary);
        letter-spacing: 0.1px;
        text-align: center;
        line-height: 28px;
    }
    @media (${queries.max_break_sm}){
        font-family: "Scandia Bold", sans-serif;
        font-weight: normal;
        font-size: 22px;
        color: var(--color-secondary);
        letter-spacing: 0.1px;
        text-align: center;
        line-height: 28px;
    }
`;

export const ContactName = styled.p`
    text-align: center;
    font-weight: normal;
    font-family: "Scandia Bold", sans-serif;
    font-size: 18px;
    color: var(--color-secondary);
    line-height: 26px;
    margin-bottom: 0px;
    @media (${queries.max_break_md}){
        font-family: "Scandia Bold", sans-serif;
        font-size: 18px;
        color: var(--color-secondary);
        text-align: center;
        line-height: 26px;
        font-weight: normal;
    }
    @media (${queries.max_break_sm}){
        font-family: "Scandia Bold", sans-serif;
        font-size: 18px;
        color: var(--color-secondary);
        text-align: center;
        line-height: 26px;
        font-weight: normal;
    }
`;

export const ContactTitle = styled.p`
    text-align: center;
    font-family: "Scandia Regular", sans-serif;
    font-size: 18px;
    color: var(--color-secondary);
    line-height: 26px;
    @media (${queries.max_break_md}){
        font-family: "Scandia Regular", sans-serif;
        font-size: 18px;
        color: var(--color-secondary);
        text-align: center;
        line-height: 26px;
        margin: 0px 40px;
    }
    @media (${queries.max_break_sm}){
        font-family: "Scandia Regular", sans-serif;
        font-size: 18px;
        color: var(--color-secondary);
        text-align: center;
        line-height: 26px;
        margin: 0px 40px;
    }
`;