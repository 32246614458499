import {
  MainContainer,
  Title,
  FAQContainer,
  QuestionContainer,
  QuestionText,
  QuestionIcon,
  AnswerContainer,
  AnswerText,
  CTAContainer,
  CTAText
} from "./Faq.styles"
import React, { useContext } from 'react';
import { ArrowDownSVG } from "../../../../../content/assets/svg/arrow_down";
import Button from "../../../global/Button";
import { AnalyticsContext } from "../../../../context";

const Faq = ({faq}) => {
  const [expanded, setExpanded] = React.useState([]);
  const { handleTealiumEvent } = useContext(AnalyticsContext);

  return(
    <MainContainer>
      <Title>Frequently asked questions</Title>
      {
        faq.map((qanda, index) => {
          return (
            <FAQContainer
              onClick={() => {
                if (expanded.filter((element) => element === index)[0] !== undefined) {
                  setExpanded(expanded.filter((element) => element !== index));
                } else {
                  setExpanded([...expanded, index]);
                }
              }}
              key={index}
            >
              <QuestionContainer>
                <QuestionText>{qanda.question}</QuestionText>
                <QuestionIcon>
                  <ArrowDownSVG rotation={
                    expanded.filter((element) => element === index)[0] !== undefined ?
                      0 :
                      180
                  } />
                </QuestionIcon>
              </QuestionContainer>
              {
                expanded.filter((element) => element === index)[0] !== undefined ?
                <AnswerContainer>
                  <AnswerText>{qanda.answer}</AnswerText>
                </AnswerContainer> :
                null
              }
            </FAQContainer>
          );
        })
      }
      <CTAContainer>
        <Button
          to="/contact-us/"
          type="primary"
          onClick={() => handleTealiumEvent({ eventcategory: "seamless to grubhub", eventaction: "resources", eventlabel: "contact us" })}
        >
          <CTAText>
            Get started today
          </CTAText>
        </Button>
      </CTAContainer>
    </MainContainer>
  );
};

export default Faq;
